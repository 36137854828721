
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Multiselect from "@vueform/multiselect";
import { onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getCompaniesData, getCustomersList } from "@/store/api/devices";

export default defineComponent({
  components: {
    Multiselect,
    Datatable,
  },
  name: "kt-invoices",
  props: {
    cardClasses: String,
  },
  methods: {
    slotName(name) {
      return "cell-" + name;
    },
  },
  setup() {
    const companyList = ref({ value: [], options: new Array({}) });
    const loading = ref<boolean>(false);
    const companiesLoading = ref<boolean>(false);
    const keys = ref<Set<any>>(new Set());
    const companyNames = ref<Record<string, any>>({});
    const tableHeader = ref([
      {
        name: "Companies",
        key: "companyName",
        sortable: false,
      },
    ]);
    const errorKeys = {
      Online: "active",
      Offline: "offline",
      "511": "deviceError",
    };
    const date = ref([]);
    const months = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const companyTableData = ref<Array<any>>([]);
    onMounted(async () => {
      companiesLoading.value = true;
      let companies = await getCustomersList();
      for (let company of companies) {
        companyList.value.options.push({
          label: company.name,
          value: company.id,
        });
        companyNames.value[company.id] = company.name;
      }
      companiesLoading.value = false;
      // loadData()
      setCurrentPageBreadcrumbs("Companies Report", [""]);
    });
    const errortype = ref({
      value: 0,
      options: ["Online", "Offline", "511"],
    });
    const fetchTableData = async () => {
      try {
        loading.value = true;
        const start = new Date(date.value[0]);
        const end = new Date(date.value[1]);
        let startYear = start.getUTCFullYear();
        let startDate = start.getUTCDate();
        let endDate = end.getUTCDate();
        let startMonth = start.getUTCMonth() + 1;
        let endMonth = end.getUTCMonth() + 1;
        let endYear = end.getUTCFullYear();
        let companies: any[] = [];
        keys.value.clear();
        let errorKey = errorKeys[errortype.value.value];
        companyTableData.value.splice(0, companyTableData.value.length);
        companyList.value.value.forEach((company) => {
          companies.push(company);
        });
        if (companies.length == 0) {
          companyList.value.options.forEach((company) => {
            companies.push(JSON.parse(JSON.stringify(company)).value);
          });
        }

        let payload = {
          start: `${startYear}-${startMonth}-${startDate}`,
          end: `${endYear}-${endMonth}-${endDate}`,
          companies,
        };

        const companiesData = await getCompaniesData(payload);

        let data: Record<string, any> = {};
        keys.value?.add("companyName");
        tableHeader.value = tableHeader.value.slice(0, 1);
        companiesData.sort((c1, c2) => {
          return c1.year - c2.year;
        });
        for (let companyData of companiesData) {
          if (data[companyData.se_account_id] === undefined) {
            data[companyData.se_account_id] = {};
          }
          let days = JSON.parse(companyData["days"]);
          let month = months[companyData.month];
          data[companyData.se_account_id]["companyName"] =
            companyNames.value[companyData.se_account_id];

          if (companyData.year < startYear || companyData.year > endYear) {
            console.log("data out of range");
          } else if (
            (companyData.year == startYear && companyData.month < startMonth) ||
            (companyData.year == endYear && companyData.month > endMonth)
          ) {
            console.log("data out of range");
          } else {
            if (companyData.year != endYear) {
              if (
                companyData.month != startMonth &&
                companyData.month != endMonth
              ) {
                for (let day in days) {
                  if (!keys.value.has(`value-${month}${day}`)) {
                    tableHeader.value.push({
                      name: `${month}-${day}`,
                      key: `value-${month}${day}`,
                      sortable: false,
                    });
                    keys.value.add(`value-${month}${day}`);
                  }
                  data[companyData.se_account_id][`value-${month}${day}`] =
                    days[day][errorKey];
                }
              } else if (companyData.month == startMonth) {
                let dates = Object.keys(days);
                let startIndex = dates.findIndex(
                  (val) => val === startDate.toString()
                );
                if (startIndex >= 0) {
                  dates = dates.slice(startIndex);
                }
                for (let day of dates) {
                  if (!keys.value.has(`value-${month}${day}`)) {
                    tableHeader.value.push({
                      name: `${month}-${day}`,
                      key: `value-${month}${day}`,
                      sortable: false,
                    });
                    keys.value.add(`value-${month}${day}`);
                  }
                  data[companyData.se_account_id][`value-${month}${day}`] =
                    days[day][errorKey];
                }
              }
            } else {
              if (
                companyData.month != startMonth &&
                companyData.month != endMonth
              ) {
                for (let day in days) {
                  if (!keys.value.has(`value-${month}${day}`)) {
                    tableHeader.value.push({
                      name: `${month}-${day}`,
                      key: `value-${month}${day}`,
                      sortable: false,
                    });
                    keys.value.add(`value-${month}${day}`);
                  }
                  data[companyData.se_account_id][`value-${month}${day}`] =
                    days[day][errorKey];
                }
              } else if (companyData.month == startMonth) {
                let dates = Object.keys(days).sort(
                  (a, b) => Number(a) - Number(b)
                );
                if (companyData.month == endMonth) {
                  let startIndex = dates.findIndex(
                    (val) => val === startDate.toString()
                  );
                  let lastIndex = dates.findIndex(
                    (val) => val === endDate.toString()
                  );

                  if (startIndex < 0 || lastIndex < 0) {
                    if (startIndex < 0 && lastIndex < 0) {
                      if (
                        endDate < Number(dates[0]) ||
                        startDate > Number(dates[dates.length - 1])
                      ) {
                        dates = [];
                      }
                    } else if (startIndex < 0) {
                      dates = dates.slice(0, lastIndex + 1);
                    } else {
                      dates = dates.slice(startIndex);
                    }
                  } else {
                    dates = dates.slice(startIndex, lastIndex + 1);
                  }
                } else {
                  let startIndex = dates.findIndex(
                    (val) => val === startDate.toString()
                  );
                  if (startIndex >= 0) {
                    dates = dates.slice(startIndex);
                  } else {
                    dates = dates.slice(0);
                  }
                }

                for (let day of dates) {
                  if (!keys.value.has(`value-${month}${day}`)) {
                    tableHeader.value.push({
                      name: `${month}-${day}`,
                      key: `value-${month}${day}`,
                      sortable: false,
                    });
                    keys.value.add(`value-${month}${day}`);
                  }

                  data[companyData.se_account_id][`value-${month}${day}`] =
                    days[day][errorKey];
                }
              } else if (companyData.month == endMonth) {
                let dates = Object.keys(days);
                let lastIndex = dates.findIndex(
                  (val) => val === endDate.toString()
                );
                if (lastIndex >= 0) {
                  dates = dates.slice(0, lastIndex + 1);
                } else {
                  dates = dates.slice(0);
                }
                for (let day of dates) {
                  if (!keys.value.has(`value-${month}${day}`)) {
                    tableHeader.value.push({
                      name: `${month}-${day}`,
                      key: `value-${month}${day}`,
                      sortable: false,
                    });
                    keys.value.add(`value-${month}${day}`);
                  }
                  data[companyData.se_account_id][`value-${month}${day}`] =
                    days[day][errorKey];
                }
              }
            }
          }
          companyTableData.value.push(data[companyData.se_account_id]);
        }
        loading.value = false;
      } catch (error) {
        console.log(error);

        loading.value = false;
        return;
      }
    };
    return {
      tableHeader,
      errortype,
      companyList,
      companyTableData,
      keys,
      date,
      loading,
      companiesLoading,
      fetchTableData,
    };
  },
});
